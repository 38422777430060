import { default as abouttKtZgeZgOcMeta } from "/var/frents-frontend-client/pages/about.vue?macro=true";
import { default as cartpMHCPxAz4TMeta } from "/var/frents-frontend-client/pages/cart.vue?macro=true";
import { default as cashflowAHFLRWl0ivMeta } from "/var/frents-frontend-client/pages/cashflow.vue?macro=true";
import { default as indexgg0L3KX8KvMeta } from "/var/frents-frontend-client/pages/catalog/[slug]/index.vue?macro=true";
import { default as indexOXO3TjMpyNMeta } from "/var/frents-frontend-client/pages/catalog/index.vue?macro=true";
import { default as catalog7TbZx8PZWCMeta } from "/var/frents-frontend-client/pages/catalog.vue?macro=true";
import { default as indexcOZQ6A1nCRMeta } from "/var/frents-frontend-client/pages/company/[id]/catalog/[slug]/index.vue?macro=true";
import { default as indexJ0z6aN5JeFMeta } from "/var/frents-frontend-client/pages/company/[id]/catalog/index.vue?macro=true";
import { default as catalog2deIivwDtgMeta } from "/var/frents-frontend-client/pages/company/[id]/catalog.vue?macro=true";
import { default as indexSuulaO2cTHMeta } from "/var/frents-frontend-client/pages/company/[id]/index.vue?macro=true";
import { default as indexVa25EreLC1Meta } from "/var/frents-frontend-client/pages/decor/[id]/index.vue?macro=true";
import { default as favoritesvqOpVqrAoTMeta } from "/var/frents-frontend-client/pages/favorites.vue?macro=true";
import { default as indexnZKH55U5pBMeta } from "/var/frents-frontend-client/pages/index.vue?macro=true";
import { default as loginl60ifXyvYSMeta } from "/var/frents-frontend-client/pages/login.vue?macro=true";
import { default as indexVjoey2kbOXMeta } from "/var/frents-frontend-client/pages/order/[id]/index.vue?macro=true";
import { default as ordersFfxu2jBZs7Meta } from "/var/frents-frontend-client/pages/orders.vue?macro=true";
import { default as profiletksHymuMcrMeta } from "/var/frents-frontend-client/pages/profile.vue?macro=true";
import { default as rentuBEMmVkfpsMeta } from "/var/frents-frontend-client/pages/rent.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: abouttKtZgeZgOcMeta || {},
    component: () => import("/var/frents-frontend-client/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: cartpMHCPxAz4TMeta || {},
    component: () => import("/var/frents-frontend-client/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "cashflow",
    path: "/cashflow",
    component: () => import("/var/frents-frontend-client/pages/cashflow.vue").then(m => m.default || m)
  },
  {
    name: catalog7TbZx8PZWCMeta?.name,
    path: "/catalog",
    component: () => import("/var/frents-frontend-client/pages/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "catalog-slug",
    path: ":slug()",
    meta: indexgg0L3KX8KvMeta || {},
    component: () => import("/var/frents-frontend-client/pages/catalog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog",
    path: "",
    meta: indexOXO3TjMpyNMeta || {},
    component: () => import("/var/frents-frontend-client/pages/catalog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: catalog2deIivwDtgMeta?.name,
    path: "/company/:id()/catalog",
    component: () => import("/var/frents-frontend-client/pages/company/[id]/catalog.vue").then(m => m.default || m),
    children: [
  {
    name: "company-id-catalog-slug",
    path: ":slug()",
    meta: indexcOZQ6A1nCRMeta || {},
    component: () => import("/var/frents-frontend-client/pages/company/[id]/catalog/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "company-id-catalog",
    path: "",
    meta: indexJ0z6aN5JeFMeta || {},
    component: () => import("/var/frents-frontend-client/pages/company/[id]/catalog/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "company-id",
    path: "/company/:id()",
    meta: indexSuulaO2cTHMeta || {},
    component: () => import("/var/frents-frontend-client/pages/company/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "decor-id",
    path: "/decor/:id()",
    meta: indexVa25EreLC1Meta || {},
    component: () => import("/var/frents-frontend-client/pages/decor/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    meta: favoritesvqOpVqrAoTMeta || {},
    component: () => import("/var/frents-frontend-client/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/frents-frontend-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginl60ifXyvYSMeta || {},
    component: () => import("/var/frents-frontend-client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "order-id",
    path: "/order/:id()",
    meta: indexVjoey2kbOXMeta || {},
    component: () => import("/var/frents-frontend-client/pages/order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/frents-frontend-client/pages/orders.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/frents-frontend-client/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "rent",
    path: "/rent",
    meta: rentuBEMmVkfpsMeta || {},
    component: () => import("/var/frents-frontend-client/pages/rent.vue").then(m => m.default || m)
  }
]